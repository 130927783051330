import React from "react";
import { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as css from "./index.module.scss";
import BoxesBkg from "../images/boxes-bkg.svg";
import BoxesTextLeft from "../images/boxes-text-left.svg";
import BoxesTextMiddle from "../images/boxes-text-middle.svg";
import BoxesTextRight from "../images/boxes-text-right.svg";
import IconRightArrow from "../images/icon-right-arrow.svg";

import { Page } from "../layout/page";
import { HeaderMergedContent } from "../layout/header_merged_content";

export default function IndexPage(props: PageProps) {
  return (
    <Page transparentHeader>
      <div className={css.bigPromo}>
        <div className={css.bigPromoText}>
          <h2>Advancing Nano Medicine with Next Generation Drug Delivery Technologies</h2>
          <a href="/technology">
            See technology <IconRightArrow className={css.icon} />
          </a>
        </div>
      </div>
      <div className={css.boxesPromo}>
        <div className={css.boxesImageContainer}>
          <BoxesBkg className={css.boxBkg} />
          <BoxesTextLeft className={css.boxTextLeft} />
          <BoxesTextMiddle className={css.boxTextMiddle} />
          <BoxesTextRight className={css.boxTextRight} />
        </div>
        <a href="/therapeutics">
          See therapeutics <IconRightArrow className={css.icon} />
        </a>
      </div>
      <div className={css.bottomPromo}>
        <div className={css.text}>
          CNano is creating a platform for first-in-class energy efficient manufacturing of
          lipid-based sustained release transdermal nano drug delivery systems for therapeutic
          applications in skin regeneration, anti-aging, atopic dermatitis, chronic pain and
          inflammation, and medical Cannabis.
        </div>
        <a href="/partnership">
          Partner with us <IconRightArrow className={css.icon} />
        </a>
      </div>
    </Page>
  );
}
