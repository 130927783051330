// extracted by mini-css-extract-plugin
export var bigPromo = "index-module--big-promo--Q3VOo";
export var bigPromoBkg = "index-module--big-promo-bkg--wefrQ";
export var bigPromoText = "index-module--big-promo-text--HrSek";
export var bottomPromo = "index-module--bottom-promo--lVhT2";
export var boxBkg = "index-module--box-bkg--p8gWg";
export var boxTextLeft = "index-module--box-text-left--7Udoq";
export var boxTextMiddle = "index-module--box-text-middle--SJgab";
export var boxTextRight = "index-module--box-text-right--BHcJG";
export var boxesImageContainer = "index-module--boxes-image-container--OfAh-";
export var boxesPromo = "index-module--boxes-promo--RwfxR";
export var fadeInDown = "index-module--fade-in-down--mVR3+";
export var fadeInLeft = "index-module--fade-in-left--0y957";
export var fadeInRight = "index-module--fade-in-right--x43zt";
export var fadeInUp = "index-module--fade-in-up--GPRIO";
export var icon = "index-module--icon--otHWp";
export var rightIcon = "index-module--right-icon--y8Tk2";
export var text = "index-module--text--kuZYQ";
export var zoomOut1 = "index-module--zoom-out1--1gkzB";
export var zoomOut2 = "index-module--zoom-out2---l7eI";